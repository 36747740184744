import * as React from 'react';
import { Map } from '../components/map';
import { Page } from '../components/page';
import { RichText } from '../components/richt_text';

import './kontakt.scss';

const position = {
  lat: 47.07103990864156,
  lng: 15.427547757144065,
};

const ContactPage: React.FunctionComponent = () => {
  return (
    <Page
      title="Kontakt"
      preloadMaps={true}
      seoDescription={`Dr. Mario Sollhart - Rechtsanwalt | Eingetragener Treuhänder. Annenstraße 25 / III / 9-10, 8020 Graz`}
    >
      <RichText className="contact_page">
        <h1>Kontakt</h1>
        <div>
          <p>
            <b>Telefon: </b>
            <a href="tel:004316832458">+43 316 832458</a>
            <br />
            <b>Email: </b>
            <a href="mailto:recht@sollhart.at">recht@sollhart.at</a>
            <br />
            <b>Fax: </b>
            <a href="tel:0043168324584">+43 316 832458-4</a>
          </p>
        </div>
        <div>
          <p>
            <span className="section_header">Öffnungszeiten</span>
            <b>Montag, Dienstag und Donnerstag</b>
            <br />
            08:00 bis 12:00
            <br />
            14:00 bis 16:00
            <br />
            <br />
            <b>Mittwoch und Freitag</b>
            <br />
            08:00 bis 12:00
          </p>
        </div>
        <div>
          <span className="section_header">Dr. Mario Sollhart</span>
          Rechtsanwalt | Eingetragener Treuhänder
          <br />
          Annenstraße 25 / III / 9-10 <br />
          8020 Graz
          <br />
          <br />
          <p>
            <b>Parkmöglichkeiten</b>
            <br />
            <a
              href="https://www.google.at/maps/dir//''/@47.0705936,15.3929864,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x476e3576e86ac3d5:0x1a0ad54e374b3388!2m2!1d15.4280057!2d47.0705405!3e2"
              target="_blank"
              rel="noreferrer"
            >
              Tiefgarage Roseggerhaus - Graz | APCOA
            </a>{' '}
            und <br /> gebührenpflichtige Parkplätze im Innenhof (Zufahrt über Elisabethinergasse)
            <br />
            <br />
            <b>Straßenbahn / Bus:</b>
            <br /> Haltestelle Roseggerhaus
          </p>
        </div>
      </RichText>
      <div className="contact_map_wrapper">
        <Map
          center={position}
          zoom={17}
          apiKey="AIzaSyDK99XFjcbjFuJC8ljeitbc-9DsSn72PD0"
          title="Kanzlei Dr. Mario Sollhart"
          text="Annenstraße 25"
          ctaText="Anfahrt"
          href="https://www.google.at/maps/dir//Annenstra%C3%9Fe+25,+8020+Graz/@47.0706251,15.4251815,16.5z/data=!4m9!4m8!1m0!1m5!1m1!1s0x476e35765c07e0e1:0x4cb89395277766d9!2m2!1d15.4275645!2d47.0709828!3e3"
          mapContainerClassName="contact_map_wrapper"
        />
      </div>
      <RichText className="contact_page">
        <div>
          <span className="section_header">Besprechungsbüro Südoststeiermark</span>
          Patzen 50
          <br />
          8355 Tieschen
          <br />
          <br />
          Termine nur nach Vereinbarung
        </div>
      </RichText>
      <div className="contact_map_wrapper last">
        <Map
          center={{ lat: 46.78222404104918, lng: 15.945435498178158 }}
          zoom={17}
          apiKey="AIzaSyDK99XFjcbjFuJC8ljeitbc-9DsSn72PD0"
          title="Besprechungsbüro Südoststeiermark"
          text={
            <>
              Patzen 50 <br />
              (Termin nach Vereinbarung)
            </>
          }
          ctaText="Anfahrt"
          href="https://www.google.at/maps/dir//Patzen+50,+8355+Patzen/@46.7821396,15.9432468,17z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x476f101f88afe197:0x1df0279f56410f97!2m2!1d15.9454355!2d46.7821359!3e2"
          mapContainerClassName="contact_map_wrapper"
        />
      </div>
    </Page>
  );
};

export default ContactPage;
